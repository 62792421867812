export const validateMessages = {
    required_name: 'จำเป็นต้องระบุชื่อที่แสดง!',
    required_mail: 'จำเป็นต้องระบุอีเมล!',
    required_pw: 'จำเป็นต้องระบุรหัสผ่าน!',
    display_name: 'ชื่อที่แสดงไม่ถูกต้อง',
    types: {
        email: 'กรุณากรอกอีเมลเป็นตัวพิมพ์เล็กและรูปแบบที่ถูกต้อง',
    },
    password: {
        count_char: 'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร',
        format_char: 'รหัสผ่านต้องเป็นตัวอักษรและตัวเลข',
    },
}

export type NotificationType = 'success' | 'info' | 'warning' | 'error'
export type MessageAlertType = {
    message: string
    description: string
    errMessage?: string
}

export const messagesAlert = {
    success: {
        message: 'เข้าสู่ระบบเรียบร้อยแล้ว',
        description: 'ยินดีต้อนรับ! 🎉',
    },
    success_login: {
        message: 'เข้าสู่ระบบเรียบร้อยแล้ว เย้',
        description: 'ยินดีต้อนรับ! 🎉',
    },
    success_payment: {
        message: 'ตรวจสอบการชำระเงินสำเร็จ',
        description: 'เลือกผลิตภัณฑ์ของคุณ 🎉',
    },
    success_create_account: {
        message: 'สร้างบัญชีผู้ใช้สำเร็จแล้ว',
        description: 'ยินดีต้อนรับ! 🎉',
    },
    success_logout: {
        message: 'สำเร็จ',
        description: 'ออกจากระบบ',
    },
    success_copy: {
        message: 'สำเร็จ',
        description: 'คัดลอกเลขที่บัญชีสำเร็จ',
    },
    success_edit_profile: {
        message: 'สำเร็จ',
        description: 'แก้ไขข้อมูลเสร็จสิ้น',
    },
    success_cancel_payment: {
        message: 'สำเร็จ',
        description: 'ยกเลิกธุรกรรมเสร็จสิ้น',
    },
    success_delete_history: {
        message: 'สำเร็จ',
        description: 'ลบประวัติเสร็จสิ้น',
    },
    info: {
        message: 'ข้อความข้อมูล',
        description: 'นี่คือข้อความข้อมูล',
    },
    warning: {
        message: 'ข้อความคำเตือน',
        description: 'นี่คือข้อความคำเตือน',
    },
    error: {
        message: 'ข้อความผิดพลาด',
        description: 'นี่คือข้อความผิดพลาด',
    },
    default: {
        message: 'ข้อความเริ่มต้น',
        description: 'นี่คือข้อความเริ่มต้น',
    },
    failed_login_invalid_password: {
        message: 'เข้าสู่ระบบล้มเหลว',
        description: 'รหัสผ่านไม่ถูกต้อง',
    },
    failed_request: {
        message: 'ล้มเหลว',
        description: 'เกิดข้อผิดพลาด',
    },
}
