import React, { useState, useEffect } from 'react'
import { Button, Image, Spin, Typography } from 'antd'
import StatusList from '../statusList'
import {
    HistoryDataRes,
    Status,
} from '../../../../model/interface/response/history'
import { ColorsConstant } from '../../../../assets/colors/Colors'

interface CardHistoryProps {
    item: HistoryDataRes['data'][0]
    handleSelectFile: (id: string, productId: string) => void
    handleDownloadFile: (id: string) => void
    handleDeleteHistory: (id: string) => void
    handleReUpload: (id: string, productId: string) => void
    handleCancelPayment: (id: string) => void
    handleVerify: (id: string, amount: number, slipUrl: string) => void
    isLoading: boolean
}

const CardHistory: React.FC<CardHistoryProps> = ({
    item,
    handleSelectFile,
    handleDownloadFile,
    handleDeleteHistory,
    handleReUpload,
    handleCancelPayment,
    handleVerify,
    isLoading,
}) => {
    const [isExpired, setIsExpired] = useState(false)
    const [timeLeft, setTimeLeft] = useState<number | null>(null)

    useEffect(() => {
        const expirationTime =
            new Date(item.uploadSlipDate).getTime() + 10 * 60 * 1000
        const updateTimeLeft = () => {
            const timeDifference = expirationTime - new Date().getTime()
            setTimeLeft(timeDifference > 0 ? timeDifference : 0)
            setIsExpired(timeDifference <= 0)
        }
        updateTimeLeft()
        const interval = setInterval(updateTimeLeft, 1000)

        return () => clearInterval(interval)
    }, [item.uploadSlipDate])

    const formatTimeLeft = (milliseconds: number) => {
        const minutes = Math.floor(milliseconds / 60000)
        const seconds = Math.floor((milliseconds % 60000) / 1000)
        return `${minutes}:${seconds.toString().padStart(2, '0')}`
    }

    return (
        <div className="card-history">
            <div className="detail">
                <div style={{ aspectRatio: '1/1' }}>
                    <Image
                        src={item.banner}
                        style={{ height: '100%' }}
                        alt={'image' + item.banner}
                    />
                </div>
                <div className="text">
                    <Typography.Paragraph
                        ellipsis={{ rows: 2 }}
                        style={{ margin: 0 }}
                    >
                        {item.productName}
                    </Typography.Paragraph>
                    <Typography.Paragraph
                        className="price"
                        style={{ color: ColorsConstant.info }}
                    >
                        {item.price.toLocaleString()} บาท
                    </Typography.Paragraph>
                    <StatusList
                        status={item.status}
                        selected={item.selected}
                        downloaded={item.downloaded}
                        errorMessage={item.slipOKRes.message}
                        slip={item.slip}
                    />
                    {item.status !== Status.VERIFIED && timeLeft !== null && (
                        <>
                            <Typography.Paragraph
                                style={{
                                    color: ColorsConstant.warning,
                                    margin: 0,
                                }}
                            >
                                {`เวลาที่เหลือ: ${formatTimeLeft(timeLeft)} นาที`}
                            </Typography.Paragraph>
                            <Typography.Paragraph
                                style={{
                                    color: ColorsConstant.warning,
                                    margin: 0,
                                }}
                            >
                                หากเวลาหมดแล้วจะไม่สามารถอัพโหลดหลักฐานการโอนเงินได้
                            </Typography.Paragraph>
                        </>
                    )}
                </div>
            </div>

            <div className="button-container">
                {item.status === Status.VERIFYING && (
                    <Button type="primary" disabled>
                        อัพโหลดหลักฐานการโอนเงิน
                    </Button>
                )}
                {item.status === Status.FAILED && (
                    <Button
                        type="primary"
                        onClick={() => handleReUpload(item.id, item.productId)}
                        disabled={isExpired}
                    >
                        อัพโหลดหลักฐานการโอนเงิน
                    </Button>
                )}
                {item.status === Status.WAITING_VERIFY && (
                    <Button
                        type="primary"
                        onClick={() =>
                            handleVerify(item.id, item.price, item.slip.url)
                        }
                    >
                        ตรวจสอบการโอนเงิน
                    </Button>
                )}
                {item.status === Status.VERIFIED ? (
                    <>
                        {!item.selected ? (
                            <Button
                                type="primary"
                                onClick={() =>
                                    handleSelectFile(item.id, item.productId)
                                }
                            >
                                เลือกไฟล์
                            </Button>
                        ) : (
                            <>
                                {isLoading && (
                                    <Spin style={{ marginLeft: '10px' }} />
                                )}
                                <Button
                                    type="primary"
                                    disabled={item.downloaded}
                                    onClick={() => handleDownloadFile(item.id)}
                                >
                                    {isLoading
                                        ? 'กำลังดาวน์โหลด...'
                                        : 'ดาวน์โหลด'}
                                </Button>
                            </>
                        )}
                        <Button
                            danger
                            disabled={!item.downloaded}
                            onClick={() => handleDeleteHistory(item.id)}
                        >
                            ลบประวัติ
                        </Button>
                    </>
                ) : (
                    <Button
                        danger
                        disabled={item.status === Status.VERIFYING}
                        onClick={() => handleCancelPayment(item.id)}
                    >
                        ยกเลิกธุรกรรม
                    </Button>
                )}
            </div>
        </div>
    )
}

export default CardHistory
