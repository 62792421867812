import React, { createContext, useContext, ReactNode, useState } from 'react'
import { AuthContextProps } from '../model/interface/contextType'
import {
    CreateAccountRequest,
    EditAccountRequest,
    User,
} from '../model/interface/response/customer'
import { CustomerService } from '../services/customer.service'
import { useNotification } from '../components/app.toast'
import { jwtDecode } from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { LoginRequest } from '../model/interface/response/auth'
import { AuthService } from '../services/auth.service'
import liff from '@line/liff'

const AuthContext = createContext<AuthContextProps | undefined>(undefined)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [user, setUser] = useState<User | null>(null)
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
    const { openNotificationWithIcon, contextHolder } = useNotification()
    const navigate = useNavigate()
    const lineLiff = process.env.REACT_APP_LINE_LIFF_ID

    React.useEffect(() => {
        const token = localStorage.getItem('access_token')
        if (token) {
            const decodeToken: User = jwtDecode(token)
            setUser(decodeToken)
            handleExpToken()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleExpToken = () => {
        const exp = user?.exp
        if (exp && Math.floor(Date.now() / 1000) >= exp) {
            logout()
        }
    }

    const createAccount = async (data: CreateAccountRequest) => {
        try {
            const createAccountRes = await CustomerService.createAccount(data)
            localStorage.setItem('access_token', createAccountRes.token)
            const decodedToken: User = jwtDecode(createAccountRes.token)
            setUser(decodedToken)
            setIsLoggedIn(true)
            openNotificationWithIcon('success', 'success_create_account')
            navigate('/')
        } catch (error) {
            openNotificationWithIcon('error', 'Create account error.')
        }
    }

    const editAccount = async (data: EditAccountRequest) => {
        if (user) {
            try {
                const resEditAccount = await CustomerService.editAccount(
                    data,
                    user?.userId
                )

                setUser((perv) => {
                    if (perv) {
                        return {
                            ...resEditAccount.data,
                            exp: perv.exp,
                            role: perv.role,
                        }
                    } else {
                        return {
                            ...resEditAccount.data,
                            exp: 0,
                            role: 'default',
                        }
                    }
                })
                openNotificationWithIcon('success', 'success_edit_profile')

                navigate('/')
            } catch (error) {
                openNotificationWithIcon('error', 'แก้ไขข้อมูลผิดพลาด')
            }
        }
    }

    const login = async (data: LoginRequest) => {
        try {
            const loginRes = await AuthService.login(data)
            localStorage.setItem('access_token', loginRes.token)
            const decodedToken: User = jwtDecode(loginRes.token)
            setUser(decodedToken)
            setIsLoggedIn(true)
            openNotificationWithIcon('success', 'success_login')
        } catch (error: unknown) {
            const err = error as { response?: { data?: { error?: string } } }
            openNotificationWithIcon(
                'error',
                'error_login',
                err.response?.data?.error
            )
        }
    }

    const logout = () => {
        localStorage.removeItem('access_token')
        localStorage.removeItem(`LIFF_STORE:${lineLiff}:clientId`)
        localStorage.removeItem(`LIFF_STORE:${lineLiff}:accessToken`)
        localStorage.removeItem(`LIFF_STORE:${lineLiff}:IDToken`)
        localStorage.removeItem(`LIFF_STORE:${lineLiff}:decodedIDToken`)
        liff.logout()
        setUser(null)
        setIsLoggedIn(false)
        openNotificationWithIcon('success', 'success_logout')
        navigate('/')
    }

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                setIsLoggedIn,
                user,
                setUser,
                createAccount,
                editAccount,
                login,
                logout,
            }}
        >
            {contextHolder}
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}
