import React, { useEffect, useState } from 'react'
import { ProductService } from '../../../../services/product.service'
import './downloadPdfPage.scss'
import { useNavigate } from 'react-router-dom'
import { Modal, Button, Spin } from 'antd'
import { useAuth } from '../../../../contexts/AuthContext'
import { ProductData } from '../../../../model/interface/response/products'

interface DownloadPDFProps {
    current: number
    numberState: number
    productId: string | null
}

const DownloadPDFPage = (props: DownloadPDFProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false)
    const [clicked, setClicked] = useState(false)
    const urlParams = new URLSearchParams(location.search)
    const refId = urlParams.get('ref')
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalContent, setModalContent] = useState({ title: '', content: '' })
    const navigate = useNavigate()
    const { user } = useAuth()
    const [product, setProduct] = useState<ProductData>()

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                if (props.productId) {
                    const data = await ProductService.getProductById(
                        props.productId
                    )
                    setProduct(data)
                }
            } catch (err) {
                console.log(err)
            }
        }

        fetchProduct()
    }, [props.productId])

    const downloadPdf = async () => {
        setIsLoading(true)
        try {
            if (refId) {
                const blob = await ProductService.getFileMerge(refId)
                const fileUrl = window.URL.createObjectURL(blob)

                const link = document.createElement('a')
                link.href = fileUrl
                link.download = `${product?.title}_${user?.displayName}.pdf`
                link.style.display = 'none'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(fileUrl)

                setClicked(true)
            }
        } catch (err) {
            console.error('Failed to fetch PDF file', err)
            showModal(
                'ดาวน์โหลดไฟล์ล้มเหลว',
                'กรุณาลองดาวน์โหลดไฟล์ PDF อีกครั้งหรือตรวจสอบการเชื่อมต่ออินเทอร์เน็ตของคุณ หากยังไม่สามารถดาวน์โหลดได้ กรุณาติดต่อแอดมิน'
            )
        } finally {
            setIsLoading(false)
        }
    }

    const showModal = (title: string, content: string) => {
        setModalContent({ title, content })
        setIsModalVisible(true)
    }

    const handleOk = () => {
        navigate(`/history`)
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <>
            <div className="perferred-option-container">
                <div className="text-container">
                    <div className="header">เลือกไฟล์ PDF เสร็จสิ้น</div>
                    <div className="text-description">
                        คุณสามารถดาวน์โหลดไฟล์ PDF ได้ที่ปุ่ม "ดาวน์โหลด"
                        ด้านล่าง
                    </div>
                </div>
                <div className="download-container">
    <img src="/images/pdf.png" alt="pdf" />
    <div className="text-container">
        <div className="text-description">{product?.title}</div>
        <div className="button-container" style={{display: 'flex', flexDirection: "column", gap: "3px"}}>
            {isLoading && (
                <Spin style={{ marginLeft: '10px' }} />
            )}
            <Button
                type="link"
                disabled={isLoading || clicked}
                onClick={downloadPdf}
            >
                {isLoading ? 'กำลังดาวน์โหลด...' : 'ดาวน์โหลด'}
            </Button>
        </div>
    </div>
</div>
            </div>

            <Modal
                title={modalContent.title}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        ยกเลิก
                    </Button>,
                    <Button key="ok" type="primary" onClick={handleOk}>
                        ตกลง
                    </Button>,
                ]}
            >
                <p>{modalContent.content}</p>
            </Modal>
        </>
    )
}

export default DownloadPDFPage
