import React from 'react'
import { notification } from 'antd'
import {
    MessageAlertType,
    NotificationType,
    messagesAlert,
} from '../model/enum/message'
import '../assets/scss/components/_modal.scss'

interface NotificationHook {
    openNotificationWithIcon: (
        type: NotificationType,
        label_alert: string,
        errMessage?: string
    ) => void
    contextHolder: React.ReactElement
}

export const useNotification = (): NotificationHook => {
    const [api, contextHolder] = notification.useNotification()

    const openNotificationWithIcon = (
        type: NotificationType,
        label_alert: string,
        errMessage?: string
    ) => {
        const { message, description } = getMessageDetails(
            type,
            label_alert,
            errMessage
        )
        const defaultStyle = {
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
        }

        const customStyle: Record<string, { color: string }> = {
            error: {
                color: 'red',
            },
            warning: {
                color: 'yellow',
            },
            success: {
                color: 'green',
            },
        }

        const mergedStyle = { ...defaultStyle, ...customStyle[type] }

        api[type]({
            message: <div style={mergedStyle}>{message}</div>,
            description,
        })
    }

    const getMessageDetails = (
        type: NotificationType,
        label_alert: string,
        errMessage?: string
    ): MessageAlertType => {
        switch (type) {
            case 'success':
                switch (label_alert) {
                    case 'success_login':
                        return messagesAlert.success_login
                    case 'success_create_account':
                        return messagesAlert.success_create_account
                    case 'success_logout':
                        return messagesAlert.success_logout
                    case 'success_edit_profile':
                        return messagesAlert.success_edit_profile
                    case 'success_edit_profile':
                        return messagesAlert.success_cancel_payment
                    case 'success_edit_profile':
                        return messagesAlert.success_delete_history
                    case 'success_payment':
                        return messagesAlert.success_payment
                    case 'success_copy':
                        return messagesAlert.success_copy
                }
                return messagesAlert.success
            case 'info':
                return messagesAlert.info
            case 'warning':
                return messagesAlert.warning
            case 'error':
                switch (label_alert) {
                    case 'error_delete_history':
                        return {
                            message: messagesAlert.error.message,
                            description:
                                'ลบประวัติล้มเหลว ไม่สามารถลบประวัติได้',
                        }
                    case 'error_login':
                        switch (errMessage) {
                            case 'invalid password': {
                                return {
                                    message:
                                        messagesAlert
                                            .failed_login_invalid_password
                                            .message,
                                    description:
                                        messagesAlert
                                            .failed_login_invalid_password
                                            .description,
                                }
                            }
                            default:
                                return messagesAlert.failed_request
                        }
                }
                return messagesAlert.failed_request
        }
    }

    return {
        openNotificationWithIcon,
        contextHolder,
    }
}
