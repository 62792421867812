/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import {
    Button,
    Empty,
    Modal,
    Pagination,
    PaginationProps,
    Spin,
    Typography,
} from 'antd'
import './historyPage.scss'
import { HistoryDataRes } from '../../model/interface/response/history'
import { useNavigate } from 'react-router-dom'
import { PaidService } from '../../services/paid.service'
import { useNotification } from '../../components/app.toast'
import { PaymentField } from '../../model/enum/payment'
import { PaymentService } from '../../services/payment.service'
import { AxiosError } from 'axios'
import CardHistory from './components/cardHistory'
import { useAuth } from '../../contexts/AuthContext'
import { User } from '../../model/interface/response/customer'
import dayjs from 'dayjs'
import FileSaver from 'file-saver'

const HistoryPage: React.FC = () => {
    const navigate = useNavigate()
    const { openNotificationWithIcon, contextHolder } = useNotification()

    const [pagination, setPagination] = React.useState<{
        page: number
        pageSize: number
    }>({ page: 1, pageSize: 10 })
    const [open, setOpen] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const { user } = useAuth()

    const onPaginationChange: PaginationProps['onChange'] = async (
        current,
        pageSize
    ) => {
        setPagination({ page: current, pageSize })
    }

    const [histories, setHistories] = React.useState<HistoryDataRes>({
        data: [],
        page: 1,
        pageSize: 10,
        total: 0,
    })
    const fetchHistories = React.useCallback(async (user?: User) => {
        try {
            const resHistories = await PaidService.getHistory(
                pagination.page,
                pagination.pageSize,
                user?.userId
            )
            setHistories(resHistories)
        } catch (error) {
            const axiosError = error as AxiosError
            console.error('Error fetching product recommendation:', error)
            openNotificationWithIcon(
                'error',
                `ไม่สามารถดึงประวัติการชำระเงินได้ กรุณารีเฟรชหน้าจอและลองใหม่ หรือหากปัญหายังคงอยู่ กรุณาติดต่อแอดมิน: ${axiosError?.message}`
            )
        }
    }, [pagination.page, pagination.pageSize])

    React.useEffect(() => {
        if (user) {
            fetchHistories(user)
        }
    }, [fetchHistories, user])

    const handleSelectFile = (id: string, productId: string) => {
        navigate(`/preferred-option?productId=${productId}&ref=${id}`)
    }

    const handleDownloadFile = async (id: string) => {
        setIsLoading(true)
        try {
            const response = await PaidService.getDownloadFile(id)
            const blob = new Blob([response], { type: 'application/pdf' })
            const fileName = `coquet_file_${dayjs().format('YYYY_MM_DD_mm_ss')}.pdf`
            FileSaver.saveAs(blob, fileName)
            await fetchHistories()
        } catch (error) {
            const axiosError = error as AxiosError
            console.log('🚀 ~ handleDownloadFile ~ error:', error)
            openNotificationWithIcon(
                'error',
                `ไม่สามารถดาวน์โหลดไฟล์ได้ กรุณาตรวจสอบและลองใหม่ หรือหากปัญหายังคงอยู่ กรุณาติดต่อแอดมิน: ${axiosError?.message}`
            )
        } finally {
            setIsLoading(false)
        }
    }

    const handleDeleteHistory = (id: string) => {
        try {
            Modal.confirm({
                title: 'คุณต้องการลบประวัติการชำระเงินหรือไม่',
                okText: 'ตกลง',
                okType: 'danger',
                cancelText: 'ยกเลิก',
                onOk: async () => {
                    try {
                        await PaidService.patchDeletedHistory(id)
                        if ((histories.total - 1) % histories.pageSize == 0)
                            setPagination((prev) => {
                                return {
                                    page: prev.page === 1 ? 1 : prev.page - 1,
                                    pageSize: prev.pageSize,
                                }
                            })
                        fetchHistories()
                        openNotificationWithIcon(
                            'success',
                            'success_delete_history'
                        )
                    } catch (error) {
                        openNotificationWithIcon(
                            'error',
                            'error_delete_history'
                        )
                    }
                },
                centered: true,
            })
        } catch (errorInfo) {
            const axiosError = errorInfo as AxiosError
            console.log('Delete Failed:', errorInfo)
            openNotificationWithIcon(
                'error',
                `เกิดข้อผิดพลาดในการลบประวัติ ไม่สามารถลบประวัติการชำระเงินได้ กรุณาตรวจสอบและลองใหม่ หรือหากปัญหายังคงอยู่ กรุณาติดต่อแอดมิน: ${axiosError?.message}`,
            )
        }
    }

    const handleReUpload = (id: string, productId: string) => {
        navigate(`/order/${productId}?refId=${id}`)
    }

    const handleCancelPayment = (id: string) => {
        try {
            Modal.confirm({
                title: 'คุณต้องการยกเลิกการชำระเงินหรือไม่',
                okText: 'ตกลง',
                okType: 'danger',
                cancelText: 'ยกเลิก',
                onOk: async () => {
                    try {
                        await PaidService.patchDeletedHistory(id)
                        if ((histories.total - 1) % histories.pageSize == 0) {
                            setPagination((prev) => {
                                return {
                                    page: prev.page === 1 ? 1 : prev.page - 1,
                                    pageSize: prev.pageSize,
                                }
                            })
                        }
                        fetchHistories()
                    } catch (error) {
                        openNotificationWithIcon(
                            'error',
                            'success_cancel_payment'
                        )
                    }
                },
                centered: true,
            })
        } catch (errorInfo) {
            const axiosError = errorInfo as AxiosError
            console.log('Cancel Payment Failed:', errorInfo)
            openNotificationWithIcon(
                'error',
                `เกิดข้อผิดพลาด ไม่สามารถทำรายการยกเลิกได้ กรุณาตรวจสอบและลองใหม่ หรือหากปัญหายังคงอยู่ กรุณาติดต่อแอดมิน: ${axiosError?.message}`
            )
        }
    }

    const handleVerify = async (
        id: string,
        amount: number,
        slipUrl: string
    ) => {
        setOpen(true)
        try {
            await PaymentService.postPaymentVerify({
                [PaymentField.PAYMENT_URL]: slipUrl,
                [PaymentField.PAYMENT_AMOUNT]: amount,
                [PaymentField.PAYMENT_ID]: id,
            })
        } catch (error) {
            const axiosError = error as AxiosError
            openNotificationWithIcon(
                'error',
                `ไม่สามารถตรวจสอบการชำระเงินได้ กรุณาตรวจสอบและลองใหม่ หรือหากปัญหายังคงอยู่ กรุณาติดต่อแอดมิน: ${axiosError?.message}`
            )
        }
        setOpen(false)
        fetchHistories()
    }

    return (
        <div className="history">
            {contextHolder}

            <Typography.Title level={2}>ประวัติการซื้อ</Typography.Title>

            <div className="lists-history">
                {histories?.data.length === 0 ? (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="ไม่มีข้อมูล"
                    />
                ) : (
                    histories?.data.map((item) => (
                        <CardHistory
                            key={item.id}
                            item={item}
                            handleSelectFile={handleSelectFile}
                            handleDownloadFile={handleDownloadFile}
                            handleDeleteHistory={handleDeleteHistory}
                            handleReUpload={handleReUpload}
                            handleCancelPayment={handleCancelPayment}
                            handleVerify={handleVerify}
                            isLoading={isLoading}
                        />
                    ))
                )}
                <div className="pagination">
                    <Pagination
                        current={histories.page}
                        pageSize={histories.pageSize}
                        total={histories.total}
                        onChange={onPaginationChange}
                        showSizeChanger
                    />
                </div>
            </div>
            <Modal
                open={open}
                closeIcon={false}
                footer={[
                    <Button
                        key="back"
                        type="primary"
                        onClick={() => setOpen(false)}
                    >
                        ยกเลิกการตรวจสอบ
                    </Button>,
                ]}
                className="modal_card"
                centered
                maskClosable={false}
            >
                <Typography.Title style={{ color: 'gray' }}>
                    กรุณารอสักครู่ ....
                </Typography.Title>
                <Spin size="large" />
                <span>ระบบกำลังตรวจสอบรายการการชำระเงินของท่าน</span>
            </Modal>
        </div>
    )
}
export default HistoryPage
