import { VerifiedDataRes } from '../model/interface/response/verify'
import { prefixApi } from './prefix.api'
import { axiosInstance, configHeader, configHeader2 } from './axios.api'
import { HistoryDataRes } from '../model/interface/response/history'

export class PaidService {
    private static url = `${prefixApi.paid}`

    public static async getVerify(refId: string): Promise<VerifiedDataRes> {
        const res = await axiosInstance.get<VerifiedDataRes>(
            `${this.url}/verify?refId=${refId}`,
            configHeader()
        )
        return res.data
    }

    public static async getHistory(
        page?: number,
        pageSize?: number,
        userId?: string
    ): Promise<HistoryDataRes> {
        const queryParams = new URLSearchParams()

        if (page) queryParams.append('page', page.toString())
        if (pageSize) queryParams.append('pageSize', pageSize.toString())

        const url = `${this.url}/histories${queryParams.toString() ? `?${queryParams.toString()}&userId=${userId}` : ''}`
        const res = await axiosInstance.get<HistoryDataRes>(url, configHeader())
        return res.data
    }

    public static async patchDeletedHistory(
        refId: string
    ): Promise<{ message: string }> {
        const res = await axiosInstance.patch<{ message: string }>(
            `${this.url}/history?refId=${refId}`,
            {},
            configHeader()
        )
        return res.data
    }

    public static async deletedHistory(
        refId: string
    ): Promise<{ message: string }> {
        const res = await axiosInstance.delete<{ message: string }>(
            `${this.url}/history?refId=${refId}`,
            configHeader()
        )
        return res.data
    }

    public static async getDownloadFile(refId: string): Promise<Blob> {
        const res = await axiosInstance.get(
            `${this.url}/get-file-merge?refId=${refId}`,
            {
                ...configHeader2(),
                responseType: 'blob',
            }
        )
        return res.data
    }
}
